.text-center {
    text-align: center;
}

.hide {
    display: none !important;
}

.show-on-small {
    display: none;
}

@media only screen and (max-width: 500px) {
    .hide-on-small {
        display: none !important;
    }

    .show-on-small {
        display: inline-block !important;
    }
}
.homepage {
    .page-banner h2 {
        margin: 48px 0 36px -2px;
        font-size: 40px;
        font-weight: 500;
        font-family: $heading-font-family;
    }

    .page-banner h2 span {
        font-weight: 500;
    }

    @media only screen and (min-width: 1024px) {
        .page-banner h2 {
            font-size: 40px;
        }
    }

    .section {
        max-width: 600px;
        margin: 32px 0 64px;
    }

    .section-heading {
        font-size: 40px;
        text-indent: -0.08em;
        line-height: 1.2;
    }

    .section-title {
        margin: 12px auto;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        color: #7F8A97;
    }

    .section-content {
        font-size: 21px;
        line-height: 1.6;
        margin: auto;
    }

    .footnote {
        margin-top: 40px;
        border-top: 1px solid #EDEDED;
        font-size: 16px;
        font-weight: 300;
        color: #7F8A97;
    }

}