a {
    &.subtle {
        text-decoration: none;
        color: #1f3d7a;
    }
}

hr {
    border: 1px solid #EDEDED;
    margin: 20px 0;
}

::-moz-selection {
    color: #001530;
    background: #EDEDED;
}

::selection {
    color: #001530;
    background: #EDEDED;
}

.social-icon {
    display: inline-block;
    margin: 12px auto;
    width: 24px;
    height: 24px;
}

.social-icon+.social-icon {
    margin-left: 20px;
}

.social-icon a {
    display: block;
}

.social-icon svg path {
    fill: #666;
}

.social-icon.instagram:hover svg path {
    fill: #E4405F;
}

.social-icon.facebook:hover svg path {
    fill: #3B5998;
}

.social-icon.twitter:hover svg path {
    fill: #1DA1F2;
}

.social-icon.linkedin:hover svg path {
    fill: #0077B5;
}

.social-icon.behance:hover svg path {
    fill: #1769FF;
}

.social-icon.dribbble:hover svg path {
    fill: #EA4C89;
}

.social-icon.medium:hover svg path {
    fill: #000000; /*#09B369;*/
}

.page-banner h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: $heading-font-family;
}

.bookshelf {
    margin: 0 -12px;
    margin-bottom: 20px;

    .book {
        width: 226px;
        height: 346px;
        display: inline-block;
        margin: 0 12px 20px;
        box-shadow: 2px 8px 16px rgba(0,0,0,0.12);
        position: relative;
        vertical-align: top;
        transition: transform ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
        border-radius: 0 4px 4px 0;
        overflow: hidden;

        @include media-query($on-palm) {
            width: 38vw;
            height: calc(1.53 * 38vw);
        }

        &:hover {
            box-shadow: 0 16px 40px rgba(0,0,0,0.16);
            transform: scale(1.01);
        }
    }
    .book .meta {
        position: absolute;
        bottom: 0;
        padding: 12px 16px;
        background: linear-gradient(0deg, rgba(2,0,36,0.6) 0%, rgba(2,0,36,0) 100%);
        color: #FFFFFF;

        .title {
            display: block;
            font-size: 16px;
            font-weight: 500;
        }
        .author {
            font-size: 12px;
            opacity: 0.6;
        }
    }
    .book .link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right,  
            rgba(0,0,0,0.02) 0%,
            rgba(0,0,0,0.05) 0.75%,
            rgba(255,255,255,0.5) 1.0%,
            rgba(255,255,255,0.6) 1.3%,
            rgba(255,255,255,0.5) 1.4%,
            rgba(255,255,255,0.3) 1.5%,
            rgba(255,255,255,0.3) 2.4%,
            rgba(0,0,0,0.05) 2.7%,
            rgba(0,0,0,0.05) 3.5%,
            rgba(255,255,255,0.3) 4%,
            rgba(255,255,255,0.3) 4.5%,
            rgba(244,244,244,0.1) 5.4%,
            rgba(244,244,244,0.1) 99%,
            rgba(144,144,144,0.2) 100%
            ); 
        box-shadow: inset 0 -1px 4px rgba(0,0,0,0.12);
    }
    .book .cover {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: 0 4px 4px 0;
        overflow: hidden;

        img {
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%
        }
    }
}
.footer-social{
    margin-bottom: 10px;
}
.footer-credits {
    color: #7F8A97;
}
@include media-query($on-palm) {
    .footer-credits, .footer-social {
        text-align: center;
    }
    
}

.insta-gallery {
    margin: 0 -15px;
    // width: 962px;
    min-height: 800px;
    text-align: center;

    @include media-query($on-palm) {
        width: 100%;
        width: calc(100% + 30px);
        margin: auto -9px;
        text-align: center;
    }
    
    @keyframes shimmer {
        0% {
            background: #ededed;
        }
        
        50% {
            background: #fcfcfc;
        }

        100% {
            background: #ededed;
        }
    }

    .photo {
        width: 299px;
        height: 299px;
        display: inline-block;
        margin: 16px 14px 16px;
        position: relative;
        vertical-align: top;
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        background: #ededed;
        animation-name: shimmer;
        animation-delay: 0;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-direction: forward;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        &:hover {
            box-shadow: 0 8px 32px rgba(0,0,0,0.12);
            transform: scale(1.01);
        }

        @include media-query($on-palm) {
            width: 39vw;
            height: calc(39vw);
            margin: 12px 9px 12px;
        }
    }
    .photo .cover {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        img {
            flex-shrink: 0;
            min-height: 100%;
            object-fit: cover;
        }
    }
    .photo .meta {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px 16px;
        padding-top: 50px;
        background: linear-gradient(0deg, rgba(2,0,36,0.9) 0%, rgba(2,0,36,0) 100%);
        color: #FFFFFF;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        @include media-query($on-palm) {
            top: 0;
        }

        .caption {
            display: block;
            font-size: 14px;
            font-weight: 400;
        }
        .likes {
            font-size: 12px;
            opacity: 0.6;
        }
    }
    .photo:hover .meta {
        opacity: 1;
    }
    .photo .link {
        background: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}