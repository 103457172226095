// List of notes
.notes-list {
    margin-bottom: $spacing-unit * 2;
}

.list-entry {
    margin-bottom: 16px;

    .note-heading {
      font-family: $heading-font-family;
      font-weight: 500;
    }
    
    .faded {
        font-size: 0.95em;
        // float: right;
        color: $grey-color;
    }
}

// Note
.notes-entry-heading {

}

#notes-entry-container {
  display: grid;
  grid-gap: 2em;
  grid-template-areas:
    "content"
    "side";

  @media (min-width: 700px) {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "content side";
  }
  content {
    
  }
}
