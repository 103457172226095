/**
 * Site header
 */
.site-header {
  // border-top: 5px solid transparent;
  // border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
  // border-bottom: 1px solid #eee; 
  z-index: 100;

  .wrapper {
    padding: 20px 16px;
    vertical-align: top;

    @include media-query($on-laptop) {
      padding-right: $spacing-unit;
      padding-left: $spacing-unit;
    }
  }
}

.site-title {
  @include relative-font-size(1.2);
  font-family: $heading-font-family;
  font-weight: 400;
  // line-height: $base-line-height * $base-font-size * 2.25;
  line-height: 44px; //size of lav button
  // letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $text-color;
  }
}
.site-title.home svg {
  opacity: 0.4;
}
.site-title.home:hover svg {
  opacity: 1;
}

.site-nav {
  float: right;
  // line-height: $base-line-height * $base-font-size * 2.25;
  line-height: 38px;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    font-family: $heading-font-family;
    color: $text-color;
    line-height: $base-line-height;
    font-weight: 350;
    position: relative;
    transition: color 80ms ease-in-out;
    
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      color: $brand-color;
    }
    
  }

  .page-link.active{
    color: #0055FF;
    position: relative;
  }

  @include media-query($on-palm) {
    position: absolute;
    right: $spacing-unit;
    text-align: left;

    label[for="nav-trigger"] {
      background-color: $background-color;
      border: 1px solid $grey-color-light;
      border-radius: 5px;
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;

      &:hover {
        background-color: #EDEDED;
      }
    }

    input:checked ~ label[for="nav-trigger"] {
      background-color: #EDEDED;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
      transition: all ease-in-out 0.2s;
    }

    input:checked ~ .trigger {
      display: block;
      padding: 10px;
      margin-top: 44px;
      background: #FFF;
      border: 1px solid $grey-color-light;
      border-radius: 5px;
      font-size: 20px;
      box-shadow: 0 8px 24px rgba($color: #000000, $alpha: 0.12);
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      padding-right: 60px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  // border-top: 1px solid $grey-color-light;
  // background: lighten($grey-color-light, 6%);
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  // font-family: $content-font-family;
  padding: $spacing-unit*1.5 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}


/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;

  time {
    color: $grey-color;
  }
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-family: $base-font-family;
  font-size: $small-font-size;
  color: $grey-color;
}

.post-breadcrumbs {
  font-family: $base-font-family;
  font-size: $small-font-size;
  color: $grey-color;
  margin-bottom: 24px;
  display: inline-block;
  border: 0;

  &:hover {
    color: $grey-color-dark;
    background: none;

    &:before {
      left: -20px;
    }
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.35);
  background: none !important;
  margin-bottom: 8px;
  letter-spacing: -0.02em;
}
.center-image {
  margin: 0 auto;
  display: block;
}
.post-title {
  @include relative-font-size(1.7);
  // letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: -0.01em;
  font-weight: 500;

  @include media-query($on-laptop) {
    @include relative-font-size(2.0);
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  *:not(h1, h2, h3, h4, pre, code){
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
